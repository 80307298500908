<template>
  <div>
    <v-container class="py-10">
      <v-row>
        <v-col md="12" class="text-center">
          <h2 class="px-5 py-5">{{ $t('Student Quiz Results Details Report') }} : {{ $t(dataTemp.student) }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" class="py-10 mb-10">
          <v-row>
            <v-col md="8">
              <h3 class="ml-5">{{ $t('subject') }} : <span style="font-weight: 100">{{ $t(dataTemp.subjects) }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Try Number') }} : <span style="font-weight: 100">{{ $t(dataTemp.attempt_number)
              }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('class') }} : <span style="font-weight: 100">{{ $t(dataTemp.grade) }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('educitional class') }} : <span style="font-weight: 100">{{ $t(dataTemp.edu_class)
              }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Time Taken') }} : <span style="font-weight: 100">{{ $t(dataTemp.time) }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Quiz Name') }} : <span style="font-weight: 100">{{ $t(dataTemp.quiz) }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Quiz Creator') }} : <span style="font-weight: 100">{{ $t(dataTemp.user) }}</span>
              </h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('date') }} : <span style="font-weight: 100">{{ $t(dataTemp.date) }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Quiz Results') }} : <span style="font-weight: 100">{{ $t(dataTemp.quiz_result)
              }}</span></h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Student Result') }} : <span style="font-weight: 100">{{ $t(dataTemp.result) }}</span>
              </h3>
            </v-col>
            <v-col md="4">
              <h3 class="ml-5">{{ $t('Percent') }} : <span style="font-weight: 100">{{ $t(dataTemp.percent) }}</span></h3>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="12">
          <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
            @re-fetch-paginated-data="fetchData($event)" :enableDelete="false" :displaySettings="false">
          </app-base-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ExportExcel from "../../../components/core/ExportExcel.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import axios from "axios";
export default {
  components: { ExportExcel, AutoCompleteField },
  data() {
    return {
      dataTemp: [],
      tableOptions: {
        tableData: [],
        tableHeaders: ["Question", "Correct Answer", "Student Answer", "Question Result", "Comments"],
        accessTableData: [["question"], ["correct_answer"], ["answer"], ["mark"], ["comment"]],
        loading: false,
        tableMeta: {
          total: 10,
          page: 1,
        },
      },
    };
  },
  methods: {
    async fetchData(page) {
      this.tableOptions.loading = true;
      let params = {
        size: 10,
        page: page,
      };
      const response = await axios.get(`/lms/student-answer/detail-report/${this.$route.params.id}`, { params });
      this.dataTemp = response.data.student_quiz;
      if (response.status == 200) {
        console.log(response.data.data);
        this.tableOptions.tableData = response.data.data,
        this.tableOptions.tableMeta.total = response.data.meta.total;
        this.tableOptions.tableMeta.page = response.data.meta.current_page;
      }
      this.tableOptions.loading = false;
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>
